import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'
import { Container } from './Container'
// import Conversion from './Converson'

const FooterIsms = () => (
  <Container size="large">
    <Inner>
      <Isms>
        <IsmsLogo>
          <IsmsLogoImage
            src="/images/logo/ISMS_ANAB_ISMS-AC_color.jpg"
            alt="ISMS_ANAB_ISMS-AC"
            width={1925 / 16}
            height={722 / 16}
          />
          <IsmsLogoCaption>IS 814625 / ISO27001</IsmsLogoCaption>
        </IsmsLogo>
        <IsmsDescription>
          情報セキュリティマネジメントシステムの国際規格である
          <br />
          ISO27001/ISMSの認証を取得しています。
        </IsmsDescription>
      </Isms>
      <Copyright>RYDE, Inc. All Rights Reserved.</Copyright>
    </Inner>
  </Container>
)
export default FooterIsms

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 40px 0;

  ${Media.mobileTablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Isms = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const IsmsLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

const IsmsLogoImage = styled(Image)``

const IsmsLogoCaption = styled.p`
  ${theme.typography.FontOverpassBold}
  font-size: 8px;
  letter-spacing: 0.1em;
`

const IsmsDescription = styled.p`
  ${theme.typography.DefNotoSansBodyBold}
  color: ${theme.colors.primary};
  font-size: 12px;

  ${Media.mobileMiddle} {
    font-size: 10px;
  }
`

const Copyright = styled.p`
  ${theme.typography.FontOverpassBold}
  color: ${theme.colors.primary};
  letter-spacing: 0.1em;

  ${Media.mobileTablet} {
    align-self: flex-end;
    font-size: 14px;
  }
`
